export const definition = {
  background: {
    "color": {
      "base1": {
        "content": "bg-base1-content",
        "default": "bg-base1-default",
        "alt": "bg-base1-alt",
        "event": {
          "focus": "focus:bg-base1-alt",
          "hover": "hover:bg-base1-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-base1-alt",
          "hover": "group-hover:bg-base1-alt",
        },
      },
      "base2": {
        "content": "bg-base2-content",
        "default": "bg-base2-default",
        "alt": "bg-base2-alt",
        "event": {
          "focus": "focus:bg-base2-alt",
          "hover": "hover:bg-base2-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-base2-alt",
          "hover": "group-hover:bg-base2-alt",
        },
      },
      "base3": {
        "content": "bg-base3-content",
        "default": "bg-base3-default",
        "alt": "bg-base3-alt",
        "event": {
          "focus": "focus:bg-base3-alt",
          "hover": "hover:bg-base3-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-base3-alt",
          "hover": "group-hover:bg-base3-alt",
        },
      },
      "base4": {
        "content": "bg-base4-content",
        "default": "bg-base4-default",
        "alt": "bg-base4-alt",
        "event": {
          "focus": "focus:bg-base4-alt",
          "hover": "hover:bg-base4-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-base4-alt",
          "hover": "group-hover:bg-base4-alt",
        },
      },
      "primary": {
        "content": "bg-primary-content",
        "default": "bg-primary-default",
        "alt": "bg-primary-alt",
        "event": {
          "focus": "focus:bg-primary-alt",
          "hover": "hover:bg-primary-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-primary-alt",
          "hover": "group-hover:bg-primary-alt",
        },
      },
      "secondary": {
        "content": "bg-secondary-content",
        "default": "bg-secondary-default",
        "alt": "bg-secondary-alt",
        "event": {
          "focus": "focus:bg-secondary-alt",
          "hover": "hover:bg-secondary-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-secondary-alt",
          "hover": "group-hover:bg-secondary-alt",
        },
      },
      "neutral": {
        "content": "bg-neutral-content",
        "default": "bg-neutral-default",
        "alt": "bg-neutral-alt",
        "event": {
          "focus": "focus:bg-neutral-alt",
          "hover": "hover:bg-neutral-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-neutral-alt",
          "hover": "group-hover:bg-neutral-alt",
        },
      },
      "accent": {
        "content": "bg-accent-content",
        "default": "bg-accent-default",
        "alt": "bg-accent-alt",
        "event": {
          "focus": "focus:bg-accent-alt",
          "hover": "hover:bg-accent-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-accent-alt",
          "hover": "group-hover:bg-accent-alt",
        },
      },
      "info": {
        "content": "bg-info-content",
        "default": "bg-info-default",
        "alt": "bg-info-alt",
        "event": {
          "focus": "focus:bg-info-alt",
          "hover": "hover:bg-info-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-info-alt",
          "hover": "group-hover:bg-info-alt",
        },
      },
      "success": {
        "content": "bg-success-content",
        "default": "bg-success-default",
        "alt": "bg-success-alt",
        "event": {
          "focus": "focus:bg-success-alt",
          "hover": "hover:bg-success-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-success-alt",
          "hover": "group-hover:bg-success-alt",
        },
      },
      "warning": {
        "content": "bg-warning-content",
        "default": "bg-warning-default",
        "alt": "bg-warning-alt",
        "event": {
          "focus": "focus:bg-warning-alt",
          "hover": "hover:bg-warning-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-warning-alt",
          "hover": "group-hover:bg-warning-alt",
        },
      },
      "danger": {
        "content": "bg-danger-content",
        "default": "bg-danger-default",
        "alt": "bg-danger-alt",
        "event": {
          "focus": "focus:bg-danger-alt",
          "hover": "hover:bg-danger-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-danger-alt",
          "hover": "group-hover:bg-danger-alt",
        },
      },
      "chart1": {
        "content": "bg-chart1-content",
        "default": "bg-chart1-default",
        "alt": "bg-chart1-alt",
        "event": {
          "focus": "focus:bg-chart1-alt",
          "hover": "hover:bg-chart1-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart1-alt",
          "hover": "group-hover:bg-chart1-alt",
        },
      },
      "chart2": {
        "content": "bg-chart2-content",
        "default": "bg-chart2-default",
        "alt": "bg-chart2-alt",
        "event": {
          "focus": "focus:bg-chart2-alt",
          "hover": "hover:bg-chart2-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart2-alt",
          "hover": "group-hover:bg-chart2-alt",
        },
      },
      "chart3": {
        "content": "bg-chart3-content",
        "default": "bg-chart3-default",
        "alt": "bg-chart3-alt",
        "event": {
          "focus": "focus:bg-chart3-alt",
          "hover": "hover:bg-chart3-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart3-alt",
          "hover": "group-hover:bg-chart3-alt",
        },
      },
      "chart4": {
        "content": "bg-chart4-content",
        "default": "bg-chart4-default",
        "alt": "bg-chart4-alt",
        "event": {
          "focus": "focus:bg-chart4-alt",
          "hover": "hover:bg-chart4-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart4-alt",
          "hover": "group-hover:bg-chart4-alt",
        },
      },
      "chart5": {
        "content": "bg-chart5-content",
        "default": "bg-chart5-default",
        "alt": "bg-chart5-alt",
        "event": {
          "focus": "focus:bg-chart5-alt",
          "hover": "hover:bg-chart5-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart5-alt",
          "hover": "group-hover:bg-chart5-alt",
        },
      },
      "chart6": {
        "content": "bg-chart6-content",
        "default": "bg-chart6-default",
        "alt": "bg-chart6-alt",
        "event": {
          "focus": "focus:bg-chart6-alt",
          "hover": "hover:bg-chart6-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart6-alt",
          "hover": "group-hover:bg-chart6-alt",
        },
      },
      "chart7": {
        "content": "bg-chart7-content",
        "default": "bg-chart7-default",
        "alt": "bg-chart7-alt",
        "event": {
          "focus": "focus:bg-chart7-alt",
          "hover": "hover:bg-chart7-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart7-alt",
          "hover": "group-hover:bg-chart7-alt",
        },
      },
      "chart8": {
        "content": "bg-chart8-content",
        "default": "bg-chart8-default",
        "alt": "bg-chart8-alt",
        "event": {
          "focus": "focus:bg-chart8-alt",
          "hover": "hover:bg-chart8-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart8-alt",
          "hover": "group-hover:bg-chart8-alt",
        },
      },
      "chart9": {
        "content": "bg-chart9-content",
        "default": "bg-chart9-default",
        "alt": "bg-chart9-alt",
        "event": {
          "focus": "focus:bg-chart9-alt",
          "hover": "hover:bg-chart9-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chart9-alt",
          "hover": "group-hover:bg-chart9-alt",
        },
      },
      "chartothers": {
        "content": "bg-chartothers-content",
        "default": "bg-chartothers-default",
        "alt": "bg-chartothers-alt",
        "event": {
          "focus": "focus:bg-chartothers-alt",
          "hover": "hover:bg-chartothers-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-chartothers-alt",
          "hover": "group-hover:bg-chartothers-alt",
        },
      },
      "disabled": {
        "content": "bg-disabled-content",
        "default": "bg-disabled-default",
        "alt": "bg-disabled-alt",
        "event": {
          "focus": "focus:bg-disabled-alt",
          "hover": "hover:bg-disabled-alt",
        },
        "group-event": {
          "focus": "group-focus:bg-disabled-alt",
          "hover": "group-hover:bg-disabled-alt",
        },
      },
    },
  },
  border: {
    none: "none",
    size: {
      none: "border-none",
      xs: "border-xs",
      s: "border-s",
      m: "border-m",
      l: "border-l",
      xl: "border-xl",
    },
    "color": {
      "base1": {
        "content": "border-base1-content",
        "default": "border-base1-default",
        "alt": "border-base1-alt",
        "event": {
          "focus": "focus:border-base1-alt",
          "hover": "hover:border-base1-alt",
        },
        "group-event": {
          "focus": "group-focus:border-base1-alt",
          "hover": "group-hover:border-base1-alt",
        },
      },
      "base2": {
        "content": "border-base2-content",
        "default": "border-base2-default",
        "alt": "border-base2-alt",
        "event": {
          "focus": "focus:border-base2-alt",
          "hover": "hover:border-base2-alt",
        },
        "group-event": {
          "focus": "group-focus:border-base2-alt",
          "hover": "group-hover:border-base2-alt",
        },
      },
      "base3": {
        "content": "border-base3-content",
        "default": "border-base3-default",
        "alt": "border-base3-alt",
        "event": {
          "focus": "focus:border-base3-alt",
          "hover": "hover:border-base3-alt",
        },
        "group-event": {
          "focus": "group-focus:border-base3-alt",
          "hover": "group-hover:border-base3-alt",
        },
      },
      "base4": {
        "content": "border-base4-content",
        "default": "border-base4-default",
        "alt": "border-base4-alt",
        "event": {
          "focus": "focus:border-base4-alt",
          "hover": "hover:border-base4-alt",
        },
        "group-event": {
          "focus": "group-focus:border-base4-alt",
          "hover": "group-hover:border-base4-alt",
        },
      },
      "primary": {
        "content": "border-primary-content",
        "default": "border-primary-default",
        "alt": "border-primary-alt",
        "event": {
          "focus": "focus:border-primary-alt",
          "hover": "hover:border-primary-alt",
        },
        "group-event": {
          "focus": "group-focus:border-primary-alt",
          "hover": "group-hover:border-primary-alt",
        },
      },
      "secondary": {
        "content": "border-secondary-content",
        "default": "border-secondary-default",
        "alt": "border-secondary-alt",
        "event": {
          "focus": "focus:border-secondary-alt",
          "hover": "hover:border-secondary-alt",
        },
        "group-event": {
          "focus": "group-focus:border-secondary-alt",
          "hover": "group-hover:border-secondary-alt",
        },
      },
      "neutral": {
        "content": "border-neutral-content",
        "default": "border-neutral-default",
        "alt": "border-neutral-alt",
        "event": {
          "focus": "focus:border-neutral-alt",
          "hover": "hover:border-neutral-alt",
        },
        "group-event": {
          "focus": "group-focus:border-neutral-alt",
          "hover": "group-hover:border-neutral-alt",
        },
      },
      "accent": {
        "content": "border-accent-content",
        "default": "border-accent-default",
        "alt": "border-accent-alt",
        "event": {
          "focus": "focus:border-accent-alt",
          "hover": "hover:border-accent-alt",
        },
        "group-event": {
          "focus": "group-focus:border-accent-alt",
          "hover": "group-hover:border-accent-alt",
        },
      },
      "info": {
        "content": "border-info-content",
        "default": "border-info-default",
        "alt": "border-info-alt",
        "event": {
          "focus": "focus:border-info-alt",
          "hover": "hover:border-info-alt",
        },
        "group-event": {
          "focus": "group-focus:border-info-alt",
          "hover": "group-hover:border-info-alt",
        },
      },
      "success": {
        "content": "border-success-content",
        "default": "border-success-default",
        "alt": "border-success-alt",
        "event": {
          "focus": "focus:border-success-alt",
          "hover": "hover:border-success-alt",
        },
        "group-event": {
          "focus": "group-focus:border-success-alt",
          "hover": "group-hover:border-success-alt",
        },
      },
      "warning": {
        "content": "border-warning-content",
        "default": "border-warning-default",
        "alt": "border-warning-alt",
        "event": {
          "focus": "focus:border-warning-alt",
          "hover": "hover:border-warning-alt",
        },
        "group-event": {
          "focus": "group-focus:border-warning-alt",
          "hover": "group-hover:border-warning-alt",
        },
      },
      "danger": {
        "content": "border-danger-content",
        "default": "border-danger-default",
        "alt": "border-danger-alt",
        "event": {
          "focus": "focus:border-danger-alt",
          "hover": "hover:border-danger-alt",
        },
        "group-event": {
          "focus": "group-focus:border-danger-alt",
          "hover": "group-hover:border-danger-alt",
        },
      },
      "chart1": {
        "content": "border-chart1-content",
        "default": "border-chart1-default",
        "alt": "border-chart1-alt",
        "event": {
          "focus": "focus:border-chart1-alt",
          "hover": "hover:border-chart1-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart1-alt",
          "hover": "group-hover:border-chart1-alt",
        },
      },
      "chart2": {
        "content": "border-chart2-content",
        "default": "border-chart2-default",
        "alt": "border-chart2-alt",
        "event": {
          "focus": "focus:border-chart2-alt",
          "hover": "hover:border-chart2-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart2-alt",
          "hover": "group-hover:border-chart2-alt",
        },
      },
      "chart3": {
        "content": "border-chart3-content",
        "default": "border-chart3-default",
        "alt": "border-chart3-alt",
        "event": {
          "focus": "focus:border-chart3-alt",
          "hover": "hover:border-chart3-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart3-alt",
          "hover": "group-hover:border-chart3-alt",
        },
      },
      "chart4": {
        "content": "border-chart4-content",
        "default": "border-chart4-default",
        "alt": "border-chart4-alt",
        "event": {
          "focus": "focus:border-chart4-alt",
          "hover": "hover:border-chart4-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart4-alt",
          "hover": "group-hover:border-chart4-alt",
        },
      },
      "chart5": {
        "content": "border-chart5-content",
        "default": "border-chart5-default",
        "alt": "border-chart5-alt",
        "event": {
          "focus": "focus:border-chart5-alt",
          "hover": "hover:border-chart5-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart5-alt",
          "hover": "group-hover:border-chart5-alt",
        },
      },
      "chart6": {
        "content": "border-chart6-content",
        "default": "border-chart6-default",
        "alt": "border-chart6-alt",
        "event": {
          "focus": "focus:border-chart6-alt",
          "hover": "hover:border-chart6-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart6-alt",
          "hover": "group-hover:border-chart6-alt",
        },
      },
      "chart7": {
        "content": "border-chart7-content",
        "default": "border-chart7-default",
        "alt": "border-chart7-alt",
        "event": {
          "focus": "focus:border-chart7-alt",
          "hover": "hover:border-chart7-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart7-alt",
          "hover": "group-hover:border-chart7-alt",
        },
      },
      "chart8": {
        "content": "border-chart8-content",
        "default": "border-chart8-default",
        "alt": "border-chart8-alt",
        "event": {
          "focus": "focus:border-chart8-alt",
          "hover": "hover:border-chart8-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart8-alt",
          "hover": "group-hover:border-chart8-alt",
        },
      },
      "chart9": {
        "content": "border-chart9-content",
        "default": "border-chart9-default",
        "alt": "border-chart9-alt",
        "event": {
          "focus": "focus:border-chart9-alt",
          "hover": "hover:border-chart9-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chart9-alt",
          "hover": "group-hover:border-chart9-alt",
        },
      },
      "chartothers": {
        "content": "border-chartothers-content",
        "default": "border-chartothers-default",
        "alt": "border-chartothers-alt",
        "event": {
          "focus": "focus:border-chartothers-alt",
          "hover": "hover:border-chartothers-alt",
        },
        "group-event": {
          "focus": "group-focus:border-chartothers-alt",
          "hover": "group-hover:border-chartothers-alt",
        },
      },
      "disabled": {
        "content": "border-disabled-content",
        "default": "border-disabled-default",
        "alt": "border-disabled-alt",
        "event": {
          "focus": "focus:border-disabled-alt",
          "hover": "hover:border-disabled-alt",
        },
        "group-event": {
          "focus": "group-focus:border-disabled-alt",
          "hover": "group-hover:border-disabled-alt",
        },
      },
    },
  },
  ring: {
    none: "none",
    size: {
      none: "ring-none",
      xs: "ring-xs",
      s: "ring-s",
      m: "ring-m",
      l: "ring-l",
    },
    "color": {
      "base1": {
        "content": "ring-base1-content",
        "default": "ring-base1-default",
        "alt": "ring-base1-alt",
        "event": {
          "focus": "focus:ring-base1-alt",
          "hover": "hover:ring-base1-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-base1-alt",
          "hover": "group-hover:ring-base1-alt",
        },
      },
      "base2": {
        "content": "ring-base2-content",
        "default": "ring-base2-default",
        "alt": "ring-base2-alt",
        "event": {
          "focus": "focus:ring-base2-alt",
          "hover": "hover:ring-base2-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-base2-alt",
          "hover": "group-hover:ring-base2-alt",
        },
      },
      "base3": {
        "content": "ring-base3-content",
        "default": "ring-base3-default",
        "alt": "ring-base3-alt",
        "event": {
          "focus": "focus:ring-base3-alt",
          "hover": "hover:ring-base3-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-base3-alt",
          "hover": "group-hover:ring-base3-alt",
        },
      },
      "base4": {
        "content": "ring-base4-content",
        "default": "ring-base4-default",
        "alt": "ring-base4-alt",
        "event": {
          "focus": "focus:ring-base4-alt",
          "hover": "hover:ring-base4-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-base4-alt",
          "hover": "group-hover:ring-base4-alt",
        },
      },
      "primary": {
        "content": "ring-primary-content",
        "default": "ring-primary-default",
        "alt": "ring-primary-alt",
        "event": {
          "focus": "focus:ring-primary-alt",
          "hover": "hover:ring-primary-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-primary-alt",
          "hover": "group-hover:ring-primary-alt",
        },
      },
      "secondary": {
        "content": "ring-secondary-content",
        "default": "ring-secondary-default",
        "alt": "ring-secondary-alt",
        "event": {
          "focus": "focus:ring-secondary-alt",
          "hover": "hover:ring-secondary-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-secondary-alt",
          "hover": "group-hover:ring-secondary-alt",
        },
      },
      "neutral": {
        "content": "ring-neutral-content",
        "default": "ring-neutral-default",
        "alt": "ring-neutral-alt",
        "event": {
          "focus": "focus:ring-neutral-alt",
          "hover": "hover:ring-neutral-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-neutral-alt",
          "hover": "group-hover:ring-neutral-alt",
        },
      },
      "accent": {
        "content": "ring-accent-content",
        "default": "ring-accent-default",
        "alt": "ring-accent-alt",
        "event": {
          "focus": "focus:ring-accent-alt",
          "hover": "hover:ring-accent-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-accent-alt",
          "hover": "group-hover:ring-accent-alt",
        },
      },
      "info": {
        "content": "ring-info-content",
        "default": "ring-info-default",
        "alt": "ring-info-alt",
        "event": {
          "focus": "focus:ring-info-alt",
          "hover": "hover:ring-info-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-info-alt",
          "hover": "group-hover:ring-info-alt",
        },
      },
      "success": {
        "content": "ring-success-content",
        "default": "ring-success-default",
        "alt": "ring-success-alt",
        "event": {
          "focus": "focus:ring-success-alt",
          "hover": "hover:ring-success-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-success-alt",
          "hover": "group-hover:ring-success-alt",
        },
      },
      "warning": {
        "content": "ring-warning-content",
        "default": "ring-warning-default",
        "alt": "ring-warning-alt",
        "event": {
          "focus": "focus:ring-warning-alt",
          "hover": "hover:ring-warning-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-warning-alt",
          "hover": "group-hover:ring-warning-alt",
        },
      },
      "danger": {
        "content": "ring-danger-content",
        "default": "ring-danger-default",
        "alt": "ring-danger-alt",
        "event": {
          "focus": "focus:ring-danger-alt",
          "hover": "hover:ring-danger-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-danger-alt",
          "hover": "group-hover:ring-danger-alt",
        },
      },
      "chart1": {
        "content": "ring-chart1-content",
        "default": "ring-chart1-default",
        "alt": "ring-chart1-alt",
        "event": {
          "focus": "focus:ring-chart1-alt",
          "hover": "hover:ring-chart1-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart1-alt",
          "hover": "group-hover:ring-chart1-alt",
        },
      },
      "chart2": {
        "content": "ring-chart2-content",
        "default": "ring-chart2-default",
        "alt": "ring-chart2-alt",
        "event": {
          "focus": "focus:ring-chart2-alt",
          "hover": "hover:ring-chart2-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart2-alt",
          "hover": "group-hover:ring-chart2-alt",
        },
      },
      "chart3": {
        "content": "ring-chart3-content",
        "default": "ring-chart3-default",
        "alt": "ring-chart3-alt",
        "event": {
          "focus": "focus:ring-chart3-alt",
          "hover": "hover:ring-chart3-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart3-alt",
          "hover": "group-hover:ring-chart3-alt",
        },
      },
      "chart4": {
        "content": "ring-chart4-content",
        "default": "ring-chart4-default",
        "alt": "ring-chart4-alt",
        "event": {
          "focus": "focus:ring-chart4-alt",
          "hover": "hover:ring-chart4-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart4-alt",
          "hover": "group-hover:ring-chart4-alt",
        },
      },
      "chart5": {
        "content": "ring-chart5-content",
        "default": "ring-chart5-default",
        "alt": "ring-chart5-alt",
        "event": {
          "focus": "focus:ring-chart5-alt",
          "hover": "hover:ring-chart5-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart5-alt",
          "hover": "group-hover:ring-chart5-alt",
        },
      },
      "chart6": {
        "content": "ring-chart6-content",
        "default": "ring-chart6-default",
        "alt": "ring-chart6-alt",
        "event": {
          "focus": "focus:ring-chart6-alt",
          "hover": "hover:ring-chart6-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart6-alt",
          "hover": "group-hover:ring-chart6-alt",
        },
      },
      "chart7": {
        "content": "ring-chart7-content",
        "default": "ring-chart7-default",
        "alt": "ring-chart7-alt",
        "event": {
          "focus": "focus:ring-chart7-alt",
          "hover": "hover:ring-chart7-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart7-alt",
          "hover": "group-hover:ring-chart7-alt",
        },
      },
      "chart8": {
        "content": "ring-chart8-content",
        "default": "ring-chart8-default",
        "alt": "ring-chart8-alt",
        "event": {
          "focus": "focus:ring-chart8-alt",
          "hover": "hover:ring-chart8-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart8-alt",
          "hover": "group-hover:ring-chart8-alt",
        },
      },
      "chart9": {
        "content": "ring-chart9-content",
        "default": "ring-chart9-default",
        "alt": "ring-chart9-alt",
        "event": {
          "focus": "focus:ring-chart9-alt",
          "hover": "hover:ring-chart9-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chart9-alt",
          "hover": "group-hover:ring-chart9-alt",
        },
      },
      "chartothers": {
        "content": "ring-chartothers-content",
        "default": "ring-chartothers-default",
        "alt": "ring-chartothers-alt",
        "event": {
          "focus": "focus:ring-chartothers-alt",
          "hover": "hover:ring-chartothers-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-chartothers-alt",
          "hover": "group-hover:ring-chartothers-alt",
        },
      },
      "disabled": {
        "content": "ring-disabled-content",
        "default": "ring-disabled-default",
        "alt": "ring-disabled-alt",
        "event": {
          "focus": "focus:ring-disabled-alt",
          "hover": "hover:ring-disabled-alt",
        },
        "group-event": {
          "focus": "group-focus:ring-disabled-alt",
          "hover": "group-hover:ring-disabled-alt",
        },
      },
    },
  },
  text: {
    "color": {
      "base1": {
        "content": "text-base1-content",
        "default": "text-base1-default",
        "alt": "text-base1-alt",
        "event": {
          "focus": "focus:text-base1-alt",
          "hover": "hover:text-base1-alt",
        },
        "group-event": {
          "focus": "group-focus:text-base1-alt",
          "hover": "group-hover:text-base1-alt",
        },
      },
      "base2": {
        "content": "text-base2-content",
        "default": "text-base2-default",
        "alt": "text-base2-alt",
        "event": {
          "focus": "focus:text-base2-alt",
          "hover": "hover:text-base2-alt",
        },
        "group-event": {
          "focus": "group-focus:text-base2-alt",
          "hover": "group-hover:text-base2-alt",
        },
      },
      "base3": {
        "content": "text-base3-content",
        "default": "text-base3-default",
        "alt": "text-base3-alt",
        "event": {
          "focus": "focus:text-base3-alt",
          "hover": "hover:text-base3-alt",
        },
        "group-event": {
          "focus": "group-focus:text-base3-alt",
          "hover": "group-hover:text-base3-alt",
        },
      },
      "base4": {
        "content": "text-base4-content",
        "default": "text-base4-default",
        "alt": "text-base4-alt",
        "event": {
          "focus": "focus:text-base4-alt",
          "hover": "hover:text-base4-alt",
        },
        "group-event": {
          "focus": "group-focus:text-base4-alt",
          "hover": "group-hover:text-base4-alt",
        },
      },
      "primary": {
        "content": "text-primary-content",
        "default": "text-primary-default",
        "alt": "text-primary-alt",
        "event": {
          "focus": "focus:text-primary-alt",
          "hover": "hover:text-primary-alt",
        },
        "group-event": {
          "focus": "group-focus:text-primary-alt",
          "hover": "group-hover:text-primary-alt",
        },
      },
      "secondary": {
        "content": "text-secondary-content",
        "default": "text-secondary-default",
        "alt": "text-secondary-alt",
        "event": {
          "focus": "focus:text-secondary-alt",
          "hover": "hover:text-secondary-alt",
        },
        "group-event": {
          "focus": "group-focus:text-secondary-alt",
          "hover": "group-hover:text-secondary-alt",
        },
      },
      "neutral": {
        "content": "text-neutral-content",
        "default": "text-neutral-default",
        "alt": "text-neutral-alt",
        "event": {
          "focus": "focus:text-neutral-alt",
          "hover": "hover:text-neutral-alt",
        },
        "group-event": {
          "focus": "group-focus:text-neutral-alt",
          "hover": "group-hover:text-neutral-alt",
        },
      },
      "accent": {
        "content": "text-accent-content",
        "default": "text-accent-default",
        "alt": "text-accent-alt",
        "event": {
          "focus": "focus:text-accent-alt",
          "hover": "hover:text-accent-alt",
        },
        "group-event": {
          "focus": "group-focus:text-accent-alt",
          "hover": "group-hover:text-accent-alt",
        },
      },
      "info": {
        "content": "text-info-content",
        "default": "text-info-default",
        "alt": "text-info-alt",
        "event": {
          "focus": "focus:text-info-alt",
          "hover": "hover:text-info-alt",
        },
        "group-event": {
          "focus": "group-focus:text-info-alt",
          "hover": "group-hover:text-info-alt",
        },
      },
      "success": {
        "content": "text-success-content",
        "default": "text-success-default",
        "alt": "text-success-alt",
        "event": {
          "focus": "focus:text-success-alt",
          "hover": "hover:text-success-alt",
        },
        "group-event": {
          "focus": "group-focus:text-success-alt",
          "hover": "group-hover:text-success-alt",
        },
      },
      "warning": {
        "content": "text-warning-content",
        "default": "text-warning-default",
        "alt": "text-warning-alt",
        "event": {
          "focus": "focus:text-warning-alt",
          "hover": "hover:text-warning-alt",
        },
        "group-event": {
          "focus": "group-focus:text-warning-alt",
          "hover": "group-hover:text-warning-alt",
        },
      },
      "danger": {
        "content": "text-danger-content",
        "default": "text-danger-default",
        "alt": "text-danger-alt",
        "event": {
          "focus": "focus:text-danger-alt",
          "hover": "hover:text-danger-alt",
        },
        "group-event": {
          "focus": "group-focus:text-danger-alt",
          "hover": "group-hover:text-danger-alt",
        },
      },
      "chart1": {
        "content": "text-chart1-content",
        "default": "text-chart1-default",
        "alt": "text-chart1-alt",
        "event": {
          "focus": "focus:text-chart1-alt",
          "hover": "hover:text-chart1-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart1-alt",
          "hover": "group-hover:text-chart1-alt",
        },
      },
      "chart2": {
        "content": "text-chart2-content",
        "default": "text-chart2-default",
        "alt": "text-chart2-alt",
        "event": {
          "focus": "focus:text-chart2-alt",
          "hover": "hover:text-chart2-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart2-alt",
          "hover": "group-hover:text-chart2-alt",
        },
      },
      "chart3": {
        "content": "text-chart3-content",
        "default": "text-chart3-default",
        "alt": "text-chart3-alt",
        "event": {
          "focus": "focus:text-chart3-alt",
          "hover": "hover:text-chart3-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart3-alt",
          "hover": "group-hover:text-chart3-alt",
        },
      },
      "chart4": {
        "content": "text-chart4-content",
        "default": "text-chart4-default",
        "alt": "text-chart4-alt",
        "event": {
          "focus": "focus:text-chart4-alt",
          "hover": "hover:text-chart4-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart4-alt",
          "hover": "group-hover:text-chart4-alt",
        },
      },
      "chart5": {
        "content": "text-chart5-content",
        "default": "text-chart5-default",
        "alt": "text-chart5-alt",
        "event": {
          "focus": "focus:text-chart5-alt",
          "hover": "hover:text-chart5-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart5-alt",
          "hover": "group-hover:text-chart5-alt",
        },
      },
      "chart6": {
        "content": "text-chart6-content",
        "default": "text-chart6-default",
        "alt": "text-chart6-alt",
        "event": {
          "focus": "focus:text-chart6-alt",
          "hover": "hover:text-chart6-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart6-alt",
          "hover": "group-hover:text-chart6-alt",
        },
      },
      "chart7": {
        "content": "text-chart7-content",
        "default": "text-chart7-default",
        "alt": "text-chart7-alt",
        "event": {
          "focus": "focus:text-chart7-alt",
          "hover": "hover:text-chart7-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart7-alt",
          "hover": "group-hover:text-chart7-alt",
        },
      },
      "chart8": {
        "content": "text-chart8-content",
        "default": "text-chart8-default",
        "alt": "text-chart8-alt",
        "event": {
          "focus": "focus:text-chart8-alt",
          "hover": "hover:text-chart8-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart8-alt",
          "hover": "group-hover:text-chart8-alt",
        },
      },
      "chart9": {
        "content": "text-chart9-content",
        "default": "text-chart9-default",
        "alt": "text-chart9-alt",
        "event": {
          "focus": "focus:text-chart9-alt",
          "hover": "hover:text-chart9-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chart9-alt",
          "hover": "group-hover:text-chart9-alt",
        },
      },
      "chartothers": {
        "content": "text-chartothers-content",
        "default": "text-chartothers-default",
        "alt": "text-chartothers-alt",
        "event": {
          "focus": "focus:text-chartothers-alt",
          "hover": "hover:text-chartothers-alt",
        },
        "group-event": {
          "focus": "group-focus:text-chartothers-alt",
          "hover": "group-hover:text-chartothers-alt",
        },
      },
      "disabled": {
        "content": "text-disabled-content",
        "default": "text-disabled-default",
        "alt": "text-disabled-alt",
        "event": {
          "focus": "focus:text-disabled-alt",
          "hover": "hover:text-disabled-alt",
        },
        "group-event": {
          "focus": "group-focus:text-disabled-alt",
          "hover": "group-hover:text-disabled-alt",
        },
      },
    },
    size: {
      xs: "text-xs",
      s: "text-s",
      m: "text-m",
      l: "text-l",
      xl: "text-xl",
      "2xl": "text-2xl",
      "3xl": "text-3xl",
      "4xl": "text-4xl",
      DEFAULT: "text",
    },
    font: {
      primary: {
        light: "font-primary-light",
        "light-italic": "font-primary-light-italic",
        regular: "font-primary",
        italic: "font-primary-italic",
        medium: "font-primary-medium",
        "medium-italic": "font-primary-medium-italic",
        bold: "font-primary-bold",
        "bold-italic": "font-primary-bold-italic",
      },
      secondary: {
        light: "font-secondary-light",
        "light-italic": "font-secondary-light-italic",
        regular: "font-secondary",
        italic: "font-secondary-italic",
        medium: "font-secondary-medium",
        "medium-italic": "font-secondary-medium-italic",
        "semi-bold": "font-secondary-semi-bold",
        "semi-bold-italic": "font-secondary-semi-bold-italic",
        bold: "font-secondary-bold",
        "bold-italic": "font-secondary-bold-italic",
      },
    },
    align: {
      left: "text-left",
      right: "text-right",
      center: "text-center",
      justify: "text-justify",
      start: "text-start",
      end: "text-end",
    },
    list: {
      ordered: "list-decimal",
      unordered: "list-disc",
      none: "list-none",
    },
  },
  svg: {
    "fill": {
      "base1": {
        "content": "fill-base1-content",
        "default": "fill-base1-default",
        "alt": "fill-base1-alt",
        "event": {
          "focus": "focus:fill-base1-alt",
          "hover": "hover:fill-base1-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-base1-alt",
          "hover": "group-hover:fill-base1-alt",
        },
      },
      "base2": {
        "content": "fill-base2-content",
        "default": "fill-base2-default",
        "alt": "fill-base2-alt",
        "event": {
          "focus": "focus:fill-base2-alt",
          "hover": "hover:fill-base2-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-base2-alt",
          "hover": "group-hover:fill-base2-alt",
        },
      },
      "base3": {
        "content": "fill-base3-content",
        "default": "fill-base3-default",
        "alt": "fill-base3-alt",
        "event": {
          "focus": "focus:fill-base3-alt",
          "hover": "hover:fill-base3-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-base3-alt",
          "hover": "group-hover:fill-base3-alt",
        },
      },
      "base4": {
        "content": "fill-base4-content",
        "default": "fill-base4-default",
        "alt": "fill-base4-alt",
        "event": {
          "focus": "focus:fill-base4-alt",
          "hover": "hover:fill-base4-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-base4-alt",
          "hover": "group-hover:fill-base4-alt",
        },
      },
      "primary": {
        "content": "fill-primary-content",
        "default": "fill-primary-default",
        "alt": "fill-primary-alt",
        "event": {
          "focus": "focus:fill-primary-alt",
          "hover": "hover:fill-primary-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-primary-alt",
          "hover": "group-hover:fill-primary-alt",
        },
      },
      "secondary": {
        "content": "fill-secondary-content",
        "default": "fill-secondary-default",
        "alt": "fill-secondary-alt",
        "event": {
          "focus": "focus:fill-secondary-alt",
          "hover": "hover:fill-secondary-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-secondary-alt",
          "hover": "group-hover:fill-secondary-alt",
        },
      },
      "neutral": {
        "content": "fill-neutral-content",
        "default": "fill-neutral-default",
        "alt": "fill-neutral-alt",
        "event": {
          "focus": "focus:fill-neutral-alt",
          "hover": "hover:fill-neutral-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-neutral-alt",
          "hover": "group-hover:fill-neutral-alt",
        },
      },
      "accent": {
        "content": "fill-accent-content",
        "default": "fill-accent-default",
        "alt": "fill-accent-alt",
        "event": {
          "focus": "focus:fill-accent-alt",
          "hover": "hover:fill-accent-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-accent-alt",
          "hover": "group-hover:fill-accent-alt",
        },
      },
      "info": {
        "content": "fill-info-content",
        "default": "fill-info-default",
        "alt": "fill-info-alt",
        "event": {
          "focus": "focus:fill-info-alt",
          "hover": "hover:fill-info-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-info-alt",
          "hover": "group-hover:fill-info-alt",
        },
      },
      "success": {
        "content": "fill-success-content",
        "default": "fill-success-default",
        "alt": "fill-success-alt",
        "event": {
          "focus": "focus:fill-success-alt",
          "hover": "hover:fill-success-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-success-alt",
          "hover": "group-hover:fill-success-alt",
        },
      },
      "warning": {
        "content": "fill-warning-content",
        "default": "fill-warning-default",
        "alt": "fill-warning-alt",
        "event": {
          "focus": "focus:fill-warning-alt",
          "hover": "hover:fill-warning-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-warning-alt",
          "hover": "group-hover:fill-warning-alt",
        },
      },
      "danger": {
        "content": "fill-danger-content",
        "default": "fill-danger-default",
        "alt": "fill-danger-alt",
        "event": {
          "focus": "focus:fill-danger-alt",
          "hover": "hover:fill-danger-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-danger-alt",
          "hover": "group-hover:fill-danger-alt",
        },
      },
      "chart1": {
        "content": "fill-chart1-content",
        "default": "fill-chart1-default",
        "alt": "fill-chart1-alt",
        "event": {
          "focus": "focus:fill-chart1-alt",
          "hover": "hover:fill-chart1-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart1-alt",
          "hover": "group-hover:fill-chart1-alt",
        },
      },
      "chart2": {
        "content": "fill-chart2-content",
        "default": "fill-chart2-default",
        "alt": "fill-chart2-alt",
        "event": {
          "focus": "focus:fill-chart2-alt",
          "hover": "hover:fill-chart2-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart2-alt",
          "hover": "group-hover:fill-chart2-alt",
        },
      },
      "chart3": {
        "content": "fill-chart3-content",
        "default": "fill-chart3-default",
        "alt": "fill-chart3-alt",
        "event": {
          "focus": "focus:fill-chart3-alt",
          "hover": "hover:fill-chart3-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart3-alt",
          "hover": "group-hover:fill-chart3-alt",
        },
      },
      "chart4": {
        "content": "fill-chart4-content",
        "default": "fill-chart4-default",
        "alt": "fill-chart4-alt",
        "event": {
          "focus": "focus:fill-chart4-alt",
          "hover": "hover:fill-chart4-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart4-alt",
          "hover": "group-hover:fill-chart4-alt",
        },
      },
      "chart5": {
        "content": "fill-chart5-content",
        "default": "fill-chart5-default",
        "alt": "fill-chart5-alt",
        "event": {
          "focus": "focus:fill-chart5-alt",
          "hover": "hover:fill-chart5-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart5-alt",
          "hover": "group-hover:fill-chart5-alt",
        },
      },
      "chart6": {
        "content": "fill-chart6-content",
        "default": "fill-chart6-default",
        "alt": "fill-chart6-alt",
        "event": {
          "focus": "focus:fill-chart6-alt",
          "hover": "hover:fill-chart6-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart6-alt",
          "hover": "group-hover:fill-chart6-alt",
        },
      },
      "chart7": {
        "content": "fill-chart7-content",
        "default": "fill-chart7-default",
        "alt": "fill-chart7-alt",
        "event": {
          "focus": "focus:fill-chart7-alt",
          "hover": "hover:fill-chart7-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart7-alt",
          "hover": "group-hover:fill-chart7-alt",
        },
      },
      "chart8": {
        "content": "fill-chart8-content",
        "default": "fill-chart8-default",
        "alt": "fill-chart8-alt",
        "event": {
          "focus": "focus:fill-chart8-alt",
          "hover": "hover:fill-chart8-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart8-alt",
          "hover": "group-hover:fill-chart8-alt",
        },
      },
      "chart9": {
        "content": "fill-chart9-content",
        "default": "fill-chart9-default",
        "alt": "fill-chart9-alt",
        "event": {
          "focus": "focus:fill-chart9-alt",
          "hover": "hover:fill-chart9-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chart9-alt",
          "hover": "group-hover:fill-chart9-alt",
        },
      },
      "chartothers": {
        "content": "fill-chartothers-content",
        "default": "fill-chartothers-default",
        "alt": "fill-chartothers-alt",
        "event": {
          "focus": "focus:fill-chartothers-alt",
          "hover": "hover:fill-chartothers-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-chartothers-alt",
          "hover": "group-hover:fill-chartothers-alt",
        },
      },
      "disabled": {
        "content": "fill-disabled-content",
        "default": "fill-disabled-default",
        "alt": "fill-disabled-alt",
        "event": {
          "focus": "focus:fill-disabled-alt",
          "hover": "hover:fill-disabled-alt",
        },
        "group-event": {
          "focus": "group-focus:fill-disabled-alt",
          "hover": "group-hover:fill-disabled-alt",
        },
      },
    },
  },
  grid: {
    column: {
      size: {
        xs: "grid-cols-xs",
        s: "grid-cols-s",
        m: "grid-cols-m",
        l: "grid-cols-l",
        xl: "grid-cols-xl",
      },
      span: {
        1: "col-span-1",
        2: "col-span-2",
        3: "col-span-3",
        4: "col-span-4",
        5: "col-span-5",
        6: "col-span-6",
        7: "col-span-7",
        8: "col-span-8",
        9: "col-span-9",
        10: "col-span-10",
        11: "col-span-11",
        12: "col-span-12",
        full: "col-span-full",
      },
      start: {
        1: "col-start-1",
        2: "col-start-2",
        3: "col-start-3",
        4: "col-start-4",
        5: "col-start-5",
        6: "col-start-6",
        7: "col-start-7",
        8: "col-start-8",
        9: "col-start-9",
        10: "col-start-10",
        11: "col-start-11",
        12: "col-start-12",
      },
      end: {
        1: "col-end-1",
        2: "col-end-2",
        3: "col-end-3",
        4: "col-end-4",
        5: "col-end-5",
        6: "col-end-6",
        7: "col-end-7",
        8: "col-end-8",
        9: "col-end-9",
        10: "col-end-10",
        11: "col-end-11",
        12: "col-end-12",
      },
    },
    row: {
      span: {
        1: "row-span-1",
        2: "row-span-2",
        3: "row-span-3",
        4: "row-span-4",
        5: "row-span-5",
        6: "row-span-6",
        7: "row-span-7",
        full: "row-span-full",
      },
      start: {
        1: "row-start-1",
        2: "row-start-2",
        3: "row-start-3",
        4: "row-start-4",
        5: "row-start-5",
        6: "row-start-6",
        7: "row-start-7",
      },
      end: {
        1: "row-end-1",
        2: "row-end-2",
        3: "row-end-3",
        4: "row-end-4",
        5: "row-end-5",
        6: "row-end-6",
        7: "row-end-7",
      },
    },
    gap: {
      none: "gap-none",
      xs: "gap-xs",
      s: "gap-s",
      m: "gap-m",
      l: "gap-l",
      xl: "gap-xl",
      "2xl": "gap-2xl",
      "3xl": "gap-3xl",
      "4xl": "gap-4xl",
      column: {
        none: "gap-x-none",
        xs: "gap-x-xs",
        s: "gap-x-s",
        m: "gap-x-m",
        l: "gap-x-l",
        xl: "gap-x-xl",
        "2xl": "gap-x-2xl",
        "3xl": "gap-x-3xl",
        "4xl": "gap-x-4xl",
      },
      row: {
        none: "gap-y-none",
        xs: "gap-y-xs",
        s: "gap-y-s",
        m: "gap-y-m",
        l: "gap-y-l",
        xl: "gap-y-xl",
        "2xl": "gap-y-2xl",
        "3xl": "gap-y-3xl",
        "4xl": "gap-y-4xl",
      },
    },
  },
  height: {
    none: "h-none",
    xs: "h-xs",
    // 12px
    s: "h-s",
    // 16px
    m: "h-m",
    // 24px
    l: "h-l",
    // 32px
    xl: "h-xl",
    // 48px
    "2xl": "h-2xl",
    // 64px
    "3xl": "h-3xl",
    // 80px
    "4xl": "h-4xl",
    full: "h-full",
  },
  width: {
    none: "w-none",
    xs: "w-xs",
    // 12px
    s: "w-s",
    // 16px
    m: "w-m",
    // 24px
    l: "w-l",
    // 32px
    xl: "w-xl",
    // 48px
    "2xl": "w-2xl",
    // 64px
    "3xl": "w-3xl",
    // 80px
    "4xl": "w-4xl",
    full: "w-full",
  },
  radius: {
    xs: "rounded-xs",
    s: "rounded-s",
    m: "rounded-m",
    l: "rounded-l",
    xl: "rounded-xl",
    full: "rounded-full",
    top: {
      xs: "rounded-t-xs",
      s: "rounded-t-s",
      m: "rounded-t-m",
      l: "rounded-t-l",
      xl: "rounded-t-xl",
      full: "rounded-t-full",
    },
    bottom: {
      xs: "rounded-b-xs",
      s: "rounded-b-s",
      m: "rounded-b-m",
      l: "rounded-b-l",
      xl: "rounded-b-xl",
      full: "rounded-b-full",
    },
    left: {
      xs: "rounded-l-xs",
      s: "rounded-l-s",
      m: "rounded-l-m",
      l: "rounded-l-l",
      xl: "rounded-l-xl",
      full: "rounded-l-full",
    },
    right: {
      xs: "rounded-r-xs",
      s: "rounded-r-s",
      m: "rounded-r-m",
      l: "rounded-r-l",
      xl: "rounded-r-xl",
      full: "rounded-r-full",
    },
  },
  spacing: {
    xs: "p-xs",
    s: "p-s",
    m: "p-m",
    l: "p-l",
    xl: "p-xl",
    "2xl": "p-2xl",
    "3xl": "p-3xl",
    "4xl": "p-4xl",
    top: {
      xs: "pt-xs",
      s: "pt-s",
      m: "pt-m",
      l: "pt-l",
      xl: "pt-xl",
      "2xl": "pt-2xl",
      "3xl": "pt-3xl",
      "4xl": "pt-4xl",
    },
    bottom: {
      xs: "pb-xs",
      s: "pb-s",
      m: "pb-m",
      l: "pb-l",
      xl: "pb-xl",
      "2xl": "pb-2xl",
      "3xl": "pb-3xl",
      "4xl": "pb-4xl",
    },
    left: {
      xs: "pl-xs",
      s: "pl-s",
      m: "pl-m",
      l: "pl-l",
      xl: "pl-xl",
      "2xl": "pl-2xl",
      "3xl": "pl-3xl",
      "4xl": "pl-4xl",
    },
    right: {
      xs: "pr-xs",
      s: "pr-s",
      m: "pr-m",
      l: "pr-l",
      xl: "pr-xl",
      "2xl": "pr-2xl",
      "3xl": "pr-3xl",
      "4xl": "pr-4xl",
    },
    vertical: {
      xs: "py-xs",
      s: "py-s",
      m: "py-m",
      l: "py-l",
      xl: "py-xl",
      "2xl": "py-2xl",
      "3xl": "py-3xl",
      "4xl": "py-4xl",
    },
    horizontal: {
      xs: "px-xs",
      s: "px-s",
      m: "px-m",
      l: "px-l",
      xl: "px-xl",
      "2xl": "px-2xl",
      "3xl": "px-3xl",
      "4xl": "px-4xl",
    },
  },
  animation: {
    duration: {
      "none": "duration-none",
      "xs": "duration-xs",
      "s": "duration-s",
      "m": "duration-m",
      "l": "duration-l",
    },
  },
};
