import { Container } from "../../Container/Container.tsx";
import { Text } from "../../Text/Text.tsx";
import { BaseDialogHeaderPropsType } from "./types.ts";
import configSapians from "@config/tailwind/tw.basic.sapians.conf.ts";

export const BaseDialogHeader = (
  {
    children,
    className,
    variant = "base1",
    toggleModal,
    onCancel,
    isCancellable = true,
  }: BaseDialogHeaderPropsType,
) => {
  className = className ?? "";
  className +=
    " grid justify-between gap-s md:gap-l pb-s pt-l px-l rounded-t-s md:rounded-t-m";
  className = className.trim();

  const buttonClassName =
    "flex w-full pt-xs outline-none focus:outline-none";
  const svgClassName = "w-full";
  const {
    colors,
    borderWidth,
  } = configSapians.theme;

  const onClickHandler = (event: MouseEvent) => {
    onCancel && onCancel(event);
    toggleModal();
  };

  return (
    <Container
      className={className}
      element={"header"}
      background={{ variant }}
      style={"grid-template-columns: auto 1rem"}
    >
      <Text
        element="h4"
        size={"l"}
        className={"font-black"}
      >
        {children}
      </Text>
      {isCancellable
        ? (
          <button
            className={buttonClassName}
            onClick={onClickHandler}
          >
            <svg
              className={svgClassName}
              viewBox="0 0 50 50"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0"
                y1="50"
                x2="50"
                y2="0"
                stroke-width={borderWidth["l"]}
                stroke={colors[variant].content}
              />
              <line
                x1="0"
                y1="0"
                x2="50"
                y2="50"
                stroke-width={borderWidth["l"]}
                stroke={colors[variant].content}
              />
            </svg>
          </button>
        )
        : null}
    </Container>
  );
};
