// deno-lint-ignore-file no-explicit-any

import { definition as globalDefinition } from "../definition.ts";

export const definition: Record<string, any> = {
  ...globalDefinition,
  size: {
    s: "h-xl pr-m pl-m",
    m: "h-2xl pr-l pl-l",
    l: "h-3xl pr-xl pl-xl",
  },
  height: {
    s: "h-m",
    m: "h-l",
    l: "h-xl",
  },
};
