import { Container } from "../../Container/mod.ts";
import { Text } from "../../Text/mod.ts";
import { useSignal } from "../../deps.ts";
import { definition } from "../definition.ts";
import { ButtonPropsType } from "../types.ts";

export const Button = (
  {
    type = "button",
    variant = "primary",
    isStyledAsLink = false,
    size = "m",
    radius = { all: "xs" },
    children,
    onClick,
    className,
    style,
    leadingIcon,
    trailingIcon,
    isDisabled = false,
    href,
    isTargetedToANewTab = false,
  }: ButtonPropsType,
) => {
  className = className ?? "";

  const isClickAnimationPlaying = useSignal(false);
  let classNameLink = "";
  let classNameIconArea = "";

  //save current variant
  let usedVariant = variant;

  //default class
  className += " " +
    "group leading-none inline-flex flex-row items-center border-none outline-none focus:outline-none";

  if (isDisabled) {
    usedVariant = "disabled";
    if (isStyledAsLink) {
      //todo : if ButtonLink are used with isDisabled=true, we have to find a better way to show disabled link whithout replace variant by base1 colors
      usedVariant = "base1";
      className += " " + "opacity-50";
    }
  }

  //link style
  if (isStyledAsLink) {
    classNameLink += " " + "underline";
    if (!isDisabled) {
      classNameLink += " " +
        "group-hover:no-underline hover:no-underline";
    }
  }

  //buttons style
  if (!isStyledAsLink) {
    className += " " + definition.background.color[usedVariant]["default"];

    //add effects if not disabled
    if (!isDisabled) {
      className += " " +
        definition.background.color[usedVariant]["event"]["hover"];
      className += " " +
        "transition-transform ease-in-out duration-l";
      if (isClickAnimationPlaying.value) {
        className += " " +
          "animate-button-click";
      }
    }
  }

  className += " " + definition.svg.fill[usedVariant].content;
  //classNameChildren += " " + definition.svg.fill[usedVariant].content;
  if (size) {
    className += " " + definition.size[size];
    classNameIconArea += " " + definition.height[size];
  }

  let spacingLeft = "ml-s";
  let spacingRight = "mr-s";
  switch (size) {
    case "s":
      spacingLeft = "ml-xs";
      spacingRight = "mr-xs";
      break;
    case "m":
      spacingLeft = "ml-s";
      spacingRight = "mr-s";
      break;
    case "l":
      spacingLeft = "ml-m";
      spacingRight = "mr-m";
      break;
  }

  if (radius && radius !== "none") {
    switch (radius) {
      case "full":
        className += " " + definition.radius[radius];
        break;

      default:
        radius.all ? className += " " + definition.radius[radius.all] : null;

        radius.top
          ? className += " " + definition.radius.top[radius.top]
          : null;

        radius.bottom
          ? className += " " + definition.radius.bottom[radius.bottom]
          : null;

        radius.left
          ? className += " " + definition.radius.left[radius.left]
          : null;

        radius.right
          ? className += " " + definition.radius.right[radius.right]
          : null;

        break;
    }
  }

  className = className.trim();
  const animateAndClick = (event: MouseEvent) => {
    isClickAnimationPlaying.value = true;
    if (onClick) {
      onClick(event);
    }
  };

  const buttonContent = (
    <>
      {leadingIcon
        ? (
          <Container
            element="span"
            className={children ? spacingRight : "none"}
          >
            {leadingIcon}
          </Container>
        )
        : <span className={classNameIconArea}></span>}
      {typeof children === "string"
        ? (
          <Text
            element="span"
            variant={usedVariant}
            size={size}
            fontStyle="bold"
            hover={false}
            focus={false}
            className={"mx-auto " + classNameLink.trim()}
          >
            {children}
          </Text>
        )
        : children}
      {trailingIcon
        ? (
          <Container
            element="span"
            className={children || leadingIcon ? spacingLeft : ""}
          >
            {trailingIcon}
          </Container>
        )
        : <span className={classNameIconArea}></span>}
    </>
  );
  return (
    <>
      {href
        ? (
          <a
            type={type}
            href={href}
            onClick={animateAndClick}
            className={className}
            style={style}
            disabled={isDisabled}
            onAnimationEnd={() => isClickAnimationPlaying.value = false}
            target={isTargetedToANewTab ? "_blank" : "_self"}
            rel={isTargetedToANewTab ? "noreferrer" : ""}
          >
            {buttonContent}
          </a>
        )
        : (
          <button
            type={type}
            onClick={animateAndClick}
            className={className}
            style={style}
            disabled={isDisabled}
            onAnimationEnd={() => isClickAnimationPlaying.value = false}
          >
            {buttonContent}
          </button>
        )}
    </>
  );
};
