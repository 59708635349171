export const IconWarning = () => {
  return (
    <div>
      <svg
        width="551"
        height="467"
        viewBox="0 0 551 467"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={"w-2xl md:w-3xl"}
        style={{ height: "auto" }}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M275.396 0L0 466.5H550.792L275.396 0ZM275.396 19.6707L17.516 456.5H533.276L275.396 19.6707Z"
          fill="black"
        />
        <path d="M254 155H296V312H254V155Z" fill="#FF6E1E" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M244 145H306V322H244V145ZM254 155V312H296V155H254Z"
          fill="black"
        />
        <path
          d="M303 375C303 390.464 290.464 403 275 403C259.536 403 247 390.464 247 375C247 359.536 259.536 347 275 347C290.464 347 303 359.536 303 375Z"
          fill="#FF6E1E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M313 375C313 395.987 295.987 413 275 413C254.013 413 237 395.987 237 375C237 354.013 254.013 337 275 337C295.987 337 313 354.013 313 375ZM275 403C290.464 403 303 390.464 303 375C303 359.536 290.464 347 275 347C259.536 347 247 359.536 247 375C247 390.464 259.536 403 275 403Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
