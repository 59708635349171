import { IconSyncAlt } from "@components/Icon/mod.ts";
import { Button } from "@components/Button/Default/Button.tsx";

export const Loader = () => {
  const iconLoader = (
    <IconSyncAlt className="animate-spin-slow" variant={"disabled"} size="s" />
  );
  return (
    <div className={"flex flex-row-reverse items-center"}>
      <Button isDisabled={true} trailingIcon={iconLoader}>
        Merci de patienter
      </Button>
    </div>
  );
};
