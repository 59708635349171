import { definition } from "../definition.ts";
import { TextPropsType } from "./types.ts";

export const Text = (
  {
    align,
    variant,
    size,
    element = "none",
    fontFamily = "primary",
    fontStyle = "regular",
    hover = false,
    focus = false,
    className,
    children,
    onClick,
  }: TextPropsType & {
    onClick?: (event: MouseEvent) => void;
  },
) => {
  className = className ?? "";
  if (variant) {
    className += " " + definition.text.color[variant].content;
  }
  if (size) {
    className += " " + definition.text.size[size];
  }
  if (align) {
    className += " " + definition.text.align[align];
  }
  className += " " + definition.text.font[fontFamily][fontStyle];

  if (hover === true && variant) {
    className += " " +
      definition.text
        .color[variant].event.hover;
  }
  if (focus === true && variant) {
    className += " " +
      definition.text
        .color[variant].event.focus;
  }

  className = className.trim();

  switch (element) {
    case "blockquote":
      return <blockquote onClick={onClick} className={className}>{children}</blockquote>;
    case "q":
      return <q onClick={onClick} className={className}>{children}</q>;
    case "abbr":
      return <abbr onClick={onClick} className={className}>{children}</abbr>;
    case "address":
      return <address onClick={onClick} className={className}>{children}</address>;
    case "cite":
      return <cite onClick={onClick} className={className}>{children}</cite>;
    case "bdo":
      return <bdo onClick={onClick} className={className}>{children}</bdo>;
    case "span":
      return <span onClick={onClick} className={className}>{children}</span>;
    case "p":
      return <p onClick={onClick} className={className}>{children}</p>;
    case "li":
      return <li onClick={onClick} className={className}>{children}</li>;
    case "ul":
      return <ul onClick={onClick} className={className}>{children}</ul>;
    case "ol":
      return <ol onClick={onClick} className={className}>{children}</ol>;
    case "h1":
      return <h1 onClick={onClick} className={className}>{children}</h1>;
    case "h2":
      return <h2 onClick={onClick} className={className}>{children}</h2>;
    case "h3":
      return <h3 onClick={onClick} className={className}>{children}</h3>;
    case "h4":
      return <h4 onClick={onClick} className={className}>{children}</h4>;
    case "h5":
      return <h5 onClick={onClick} className={className}>{children}</h5>;
    case "h6":
      return <h6 onClick={onClick} className={className}>{children}</h6>;
    case "div":
      return <div onClick={onClick} className={className}>{children}</div>;
  }

  return (
    <>
      {children}
    </>
  );
};
