import { signal } from "./deps.ts";
import { FormStoreType } from "./types.ts";

export const FormStore = signal<FormStoreType>({
  email: null,
  uniqueId: null,
  token: null,
});

//Signals around CallOut
export const IsResidenceSetNotToFrench = signal(false);
export const IsNationalitySetNotToFrench = signal(false);
export const IsFromUS = signal(false);
export const IsUSCitizen = signal(false);

//Signals around MaritalStatus
export const IsMaritalStatusSetToMarried = signal(false);
export const IsMaritalStatusSetToPacse = signal(false);
export const IsMaritalStatusSetToMarriedOrPacse = signal(false);

//Signals around OwnerMainResidence
export const IsOwnerMainResidenceSetToTrue = signal(false);

//Signals around OwnerMoreEstates
export const IsOwnerMoreEstatesSetToTrue = signal(false);

//Signals around PocketSecurity
export const IsPocketSecuritySetToTrue = signal(false);

//Signals around shortTermProjectLiquidity
export const IsShortTermProjectLiquiditySetToTrue = signal(false);

export const IsDialogErrorPromptOpened = signal(false);
export const IsDialogConfirmMailBackupSent = signal(false);

export const IsFormValid = signal(false);