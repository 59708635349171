import { Container } from "../../Container/Container.tsx";
import {
  OneButton,
  ThreeButtons,
  TwoButtons,
} from "./FooterWithButtons/mod.ts";
import { BaseDialogFooterPropsType } from "./types.ts";

export const BaseDialogFooter = (
  {
    buttonsContent = [],
    className,
    variant = "base1",
    toggleModal,
    isOpened = false,
  }: BaseDialogFooterPropsType,
) => {
  className = className ?? "";
  className += " " + "pt-s pb-l px-l rounded-b-s md:rounded-b-m";
  className = className.trim();

  if (buttonsContent.length > 3) {
    throw new Error("To much button for modal (3 max)");
  }

  let content = <div />;

  if (buttonsContent.length === 1) {
    content = (
      <OneButton
        isOpened={isOpened}
        primaryButton={buttonsContent[0]}
        toggleModal={toggleModal}
      />
    );
  }

  if (buttonsContent.length === 2) {
    content = (
      <TwoButtons
        isOpened={isOpened}
        primaryButton={buttonsContent[0]}
        secondaryButton={buttonsContent[1]}
        toggleModal={toggleModal}
      />
    );
  }

  if (buttonsContent.length === 3) {
    content = (
      <ThreeButtons
        isOpened={isOpened}
        primaryButton={buttonsContent[0]}
        secondaryButton={buttonsContent[1]}
        neutralButton={buttonsContent[2]}
        toggleModal={toggleModal}
      />
    );
  }

  return (
    <Container
      className={className}
      element={"footer"}
      background={{ variant }}
    >
      {content}
    </Container>
  );
};
