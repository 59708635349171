import { Button } from "../../../Button/Default/mod.ts";
import { useEffect, useSignal } from "../../../deps.ts";
import { Loader } from "./Loader.tsx";
import { OneButtonType } from "./types.ts";

export const OneButton = (
  {
    primaryButton,
    toggleModal,
    isOpened = false,
  }: OneButtonType,
) => {
  const isLoading = useSignal(false);
  useEffect(() => {
    isLoading.value = false;
  }, [isOpened]);

  const onClickOnPrimaryButtonHandler = (event: MouseEvent) => {
    if (!primaryButton.isManualToggle) {
      toggleModal();
    } else {
      isLoading.value = true;
    }
    primaryButton.onClick && primaryButton.onClick(event);
  };

  if (isLoading.value) {
    return <Loader />;
  }
  return (
    <div className={"flex flex-col sm:flex-row justify-center"}>
      <div></div>
      <div className={"grid grid-cols-1 gap-none sm:grid-cols-none"}>
        <div></div>
        <Button
          variant={primaryButton.variant}
          leadingIcon={primaryButton.leadingIcon}
          trailingIcon={primaryButton.trailingIcon}
          onClick={onClickOnPrimaryButtonHandler}
        >
          {primaryButton.text}
        </Button>
      </div>
    </div>
  );
};
