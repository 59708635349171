import { definition } from "../../definition.ts";
import { IconPropsType } from "../types.ts";

export const IconBulb = (
    {
        className,
        variant,
        size = "s",
    }: IconPropsType,
) => {
    className = className ?? "";
    if (variant) {
        className += " " + definition.svg.fill[variant].content;
    }

    let width = 32;
    let height = 32;
    switch (size) {
        case "s":
            width = 16;
            height = 16;
            break;
        case "m":
            width = 24;
            height = 24;
            break;
        case "l":
            width = 32;
            height = 32;
            break;
    }
    return (
        <svg
            width="175"
            height="181"
            viewBox="0 0 457 497"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1_12)">
                <rect width="369" height="497" fill="white" />
                <path
                    d="M243.57 386.11H125.36V416.46H243.57V386.11Z"
                    fill="#3A4642"
                />
                <path
                    d="M146.07 477.45H222.86L243.57 446.9H125.36L146.07 477.45Z"
                    fill="#3A4642"
                />
                <path
                    d="M243.57 416.46H125.36V446.9H243.57V416.46Z"
                    fill="#FFFF69"
                />
                <path
                    d="M206.04 477.45C206.04 488.09 197.42 496.71 186.78 496.71C176.14 496.71 167.52 488.09 167.52 477.45H206.04Z"
                    fill="#FFFF69"
                />
                <path
                    d="M184.66 0.419983C184.66 0.419983 149.18 7.35999 149.18 124.78C149.18 251.41 176.41 294.55 176.41 386.11H243.58C285.99 307.77 368.84 262.99 368.84 164.66C368.84 125.33 352.82 0.419983 184.67 0.419983H184.66Z"
                    fill="#3A4642"
                />
                <path
                    d="M184.27 0.419983C184.27 0.419983 219.75 7.35999 219.75 124.78C219.75 251.41 192.52 294.55 192.52 386.11H125.35C82.94 307.77 0.0899658 262.99 0.0899658 164.66C0.0899658 125.33 16.11 0.419983 184.26 0.419983H184.27Z"
                    fill="#FFFF69"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_12">
                    <rect width="369" height="497" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
