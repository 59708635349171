import { definition } from "../definition.ts";
import { ContainerPropsType } from "./types.ts";

export const Container = (
  {
    children,
    background,
    content,
    radius,
    border,
    spacing,
    className,
    element,
    style,
  }: ContainerPropsType,
) => {
  className = className ?? "";

  const { size = "xs", variant } = border ?? {};
  if (variant) {
    className += " " + definition.border.color[variant].default;
    className += " " + definition.border.size[size];
  }

  if (content) {
    const { variant } = content ?? {};
    if (variant) {
      className += " " + definition.text.color[variant]["content"];
    }
  }

  if (background) {
    const { variant } = background ?? {};

    if (variant) {
      className += " " + definition.background.color[variant]["default"];
    }
    if (!content && variant) {
      className += " " + definition.text.color[variant]["content"];
    }
  }

  if (spacing && spacing !== "none") {
    if (typeof spacing === "string") {
      className += " " + definition.spacing[spacing];
    } else {
      spacing.top
        ? className += " " + definition.spacing.top[spacing.top]
        : null;

      spacing.bottom
        ? className += " " + definition.spacing.bottom[spacing.bottom]
        : null;

      spacing.left
        ? className += " " + definition.spacing.left[spacing.left]
        : null;

      spacing.right
        ? className += " " + definition.spacing.right[spacing.right]
        : null;

      spacing.horizontal
        ? className += " " + definition.spacing.horizontal[spacing.horizontal]
        : null;

      spacing.vertical
        ? className += " " + definition.spacing.vertical[spacing.vertical]
        : null;
    }
  }

  if (radius && radius !== "none") {
    switch (radius) {
      case "full":
        className += " " + definition.radius[radius];
        break;

      default:
        radius.all && radius.all !== "none"
          ? className += " " + definition.radius[radius.all]
          : null;

        radius.top && radius.top !== "none"
          ? className += " " + definition.radius.top[radius.top]
          : null;

        radius.bottom && radius.bottom !== "none"
          ? className += " " + definition.radius.bottom[radius.bottom]
          : null;

        radius.left && radius.left !== "none"
          ? className += " " + definition.radius.left[radius.left]
          : null;

        radius.right && radius.right !== "none"
          ? className += " " + definition.radius.right[radius.right]
          : null;

        break;
    }
  }

  className = className.trim();

  switch (element) {
    case "header":
      return <header style={style} className={className}>{children}</header>;
    case "main":
      return <main style={style} className={className}>{children}</main>;
    case "nav":
      return <nav style={style} className={className}>{children}</nav>;
    case "section":
      return <section style={style} className={className}>{children}</section>;
    case "article":
      return <article style={style} className={className}>{children}</article>;
    case "aside":
      return <aside style={style} className={className}>{children}</aside>;
    case "footer":
      return <footer style={style} className={className}>{children}</footer>;
    case "details":
      return <details style={style} className={className}>{children}</details>;
    case "summary":
      return <summary style={style} className={className}>{children}</summary>;
    case "span":
      return <span style={style} className={className}>{children}</span>;
    case "label":
      return <label style={style} className={className}>{children}</label>;
    case "ul":
      return <ul style={style} className={className}>{children}</ul>;
    case "ol":
      return <ol style={style} className={className}>{children}</ol>;
    case "li":
      return <li style={style} className={className}>{children}</li>;
  }

  return <div style={style} className={className}>{children}</div>;
};
