import { Container } from "../../Container/mod.ts";
import { BaseDialogBodyPropsType } from "./types.ts";

export const BaseDialogBody = (
  {
    children,
    className,
    variant = "base1",
  }: BaseDialogBodyPropsType,
) => {
  className = className ?? "";
  className += " py-s px-l ";
  className = className.trim();

  return (
    <Container
      className={className}
      element={"div"}
      background={{ variant }}
    >
      {children}
    </Container>
  );
};
